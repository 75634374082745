.sentinterest-container {
  padding-top: 6%;
}

.logo-header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.title-block {
  text-align: center;
  margin-top: 5%;
}

.interested-first-title {
  font-size: 50px;
  color: #e74a54;
}

.interested-second-title {
  font-size: 25px;
  color: #0f40b4;
  margin-top: -1%;
}

.below-image-text {
  margin-top: 2%;
  color: #e74a54;
  font-size: 25px;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.blue-text {
  color: #0f40b4;
}

.footer-text {
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  padding-bottom: 10%;
  font-size: 20px;
}

@media only screen and (max-width: 430px), screen and (max-height: 680px) {
  .interested-first-title {
    font-size: xx-large;
  }

  .interested-second-title,
  .below-image-text {
    font-size: x-large;
  }

  .footer-text {
    font-size: large;
  }
}

@media only screen and (max-width: 300px), screen and (max-height: 660px) {
  .interested-first-title {
    font-size: x-large;
  }

  .interested-second-title,
  .below-image-text {
    font-size: large;
  }

  .footer-text {
    font-size: medium;
  }
}
