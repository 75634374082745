.dataFooter-container {
  font-family: "Heebo-Bold", sans-serif;
  margin-top: 5%;
  margin-bottom: 5%;
  width: 100%;
  font-size: large;
}

.data-custom-button {
  background-color: #0f40b4;
  color: white;
  border: 2px solid #e74a54;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  position: relative;
  width: 100%;
  transition: height 0.3s ease;
  overflow: hidden;
}

.recommended {
  display: block;
  font-size: large;
  color: #e74a54;
}

.data-form-container {
  flex-direction: column;
  background-color: #0f40b4;
  border: 2px #e74a54;
  width: 100%;
  box-sizing: border-box;
  transition: max-height 0.3s ease;
  overflow: hidden;
  gap: 0.3rem;
  margin: 0 auto;
  display: flex;
}

.data-form-row-style {
  display: flex;
  justify-content: center;
}

.data-form-container input,
.data-form-container {
  width: 100%;
  font-size: medium;
  box-sizing: border-box;
}

.data-col {
  margin-bottom: 1.5%;
}

.data-submit-button {
  background-color: #e74a54;
  color: white;
  cursor: pointer;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  box-sizing: border-box;
}

.or-buffer {
  color: #0f40b4;
}

.submit-button:hover {
  background-color: darkred;
}

.expand {
  display: block;
  max-height: 800px;
}

.data-full-name {
  line-height: 2rem;
}

.data-full-name::placeholder {
  color: #0f40b4;
  padding-right: 2.5%;
}

.data-phone {
  direction: ltr;
  text-align: right;
}

.data-phone-input input::placeholder {
  color: #0f40b4;
}

.data-phone-input {
  align-items: center;
}

.data-cities-select {
  direction: rtl;
  width: 100%;
  text-align: right;
  color: black;
}

.data-cities-select-placeholder {
  color: #0f40b4;
  direction: rtl;
}

.data-email {
  direction: rtl;
  text-align: right;
  padding-right: 2.5%;
  line-height: 2rem;
}

.data-form-input {
  font-size: 16px;
  box-sizing: border-box;
}

.form-input::placeholder {
  color: #0f40b4;
  text-align: right;
}

.data-form-control::placeholder {
  color: #0f40b4;
  text-align: right;
}

.data-gender {
  direction: rtl;
  text-align: right;
  font-size: medium;
  width: 100%;
  padding: 0.3rem 0.5rem;
}

.data-birthDate {
  padding: 0.3rem 0.5rem;
  color: #0f40b4;
  direction: rtl;
  width: 500px;
}

.react-datepicker {
  margin: -5%;
}

.react-datepicker-wrapper {
  width: 100%;
}

select:invalid {
  color: #0f40b4;
}

option {
  color: black;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: -6%;
}

.data-error-message {
  color: red;
  text-align: center;
}

.barLoader {
  text-align: center;
  justify-content: center;
  margin-bottom: 2%;
  width: 100% !important;
}

input[type="date"]:before {
  content: attr(placeholder) !important;
  color: #aaa;
  margin-right: 0.5em;
}

input[type="date"]:focus:before,
input[type="date"]:valid:before {
  content: "ddd";
}

.Toastify__toast-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5); 
  z-index: 9999; 
}

.custom-toast {
  background-color: #ffe6e6; 
  color: #cc0000;
  border: 1px solid #cc0000;
  border-radius: 8px;
  padding: 20px;
  font-size: 16px;
  font-family: 'Arial', sans-serif;
  font-weight: bold;
  text-align: center;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
}

.custom-toast-body {
  text-align: center;
}

.centered-toast {
  align-self: center;
}