@font-face {
  font-family: "Heebo-Bold";
  src: url("../src/Shared/Heebo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Heebo-Regular";
  src: url("../src/Shared/Heebo-Regular.ttf") format("truetype");
}

body {
  font-family: "Heebo-Bold", sans-serif;
}
