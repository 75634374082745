.description-container {
  white-space: pre-wrap;
  text-align: right;
}

.description-title {
  color: #e74a54;
  font-weight: bold;
  text-align: center;
  border-radius: 10px;
  min-width: fit-content;
  margin-top: 2px;
  font-size: 30px;
}

.description-body {
  color: #0f40b4;
  font-size: 25px;
  margin-top: 5%;
}

.body-text {
  font-family: "Heebo-Regular", sans-serif;
}

.demands-text {
  font-family: "Heebo-Regular", sans-serif;
}

.description-arrow {
  border: solid #0f40b4;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

@media only screen and (max-width: 430px), screen and (max-height: 1000px) {
  .description-title {
    font-size: x-large;
  }
  .description-body {
    font-size: large;
  }
}

@media only screen and (max-width: 300px), screen and (max-height: 700px) {
  .description-title {
    font-size: large;
  }
  .description-body {
    font-size: medium;
  }
}
