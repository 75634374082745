.title-block {
  text-align: center;
}

.data-first-title {
  font-size: xx-large;
  color: #e74a54;
}

.data-second-title {
  font-size: x-large;
  color: #0f40b4;
  margin-top: -1%;
}

@media only screen and (max-width: 430px), screen and (max-height: 680px) {
  .first-title {
    font-size: xx-large;
  }

  .second-title {
    font-size: x-large;
  }
}

@media only screen and (max-width: 300px), screen and (max-height: 660px) {
  .first-title {
    font-size: x-large;
  }

  .second-title {
    font-size: medium;
  }
}
