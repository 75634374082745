.title-block {
  text-align: center;
}

.first-title {
  font-size: xx-large;
  color: #0f40b4;
}

.second-title {
  font-size: 25px;
  color: #e74a54;
  margin-top: -1%;
}

@media only screen and (max-width: 430px), screen and (max-height: 680px) {
  .first-title {
    font-size: xx-large;
  }

  .second-title {
    font-size: x-large;
  }
}

@media only screen and (max-width: 300px), screen and (max-height: 660px) {
  .first-title {
    font-size: x-large;
  }

  .second-title {
    font-size: medium;
  }
}
