.full-name {
  margin-top: 2%;
  margin-bottom: 2%;
  width: 100%;
  border: 3px solid #e74a54;
  padding: 0.375rem 0.75rem;
}

.full-name::placeholder {
  margin-right: 20px;
  color: #0f40b4;
}

.phone {
  margin-top: 1.5%;
  margin-bottom: 2%;
  direction: ltr;
  text-align: right;
}

.phone-input {
  align-items: center;
}

.cities-select {
  direction: rtl;
  width: 100%;
  text-align: right;
}

.cities-select-placeholder {
  color: #0f40b4;
  direction: rtl;
}

.phone::placeholder {
  font-size: xx-large;
  direction: rtl;
  position: absolute;
}

.phone .form-control::placeholder {
  text-align: right;
  color: #0f40b4;
}

.error-message {
  color: red;
  text-align: center;
  margin-top: -6%;
}

.form-field-button {
  background-color: #0f40b4;
  border: 3px solid #e74a54;
  color: white;
  width: 100%;
  margin-bottom: 7%;
  font-family: "Heebo-Bold";
  font-size: large;
  padding: 1%;
}

.form-arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}
