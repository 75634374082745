.preview-container {
  max-width: 375px;
  max-height: 667px;
}

.preview-job-information {
  margin-top: 2%;
  max-height: 10em;
  overflow: hidden;
  position: relative;
}

.preview-full-job-information {
  margin-top: 2%;
  overflow: auto;
}
