.title {
  text-align: center;
}

.main-image {
  text-align: center;
  align-items: center;
  align-content: center;
}

.container {
  align-content: center;
  justify-content: center;
  align-items: center;
}

.header {
  margin-bottom: 3%;
}

.footer {
  margin-bottom: 5%;
}

.continue-button {
  text-align: center;
  color: #0f40b4;
  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;
  margin-top: -1%;
  margin-bottom: -1%;
  height: 1.5rem;
}

.job-information {
  margin-top: 2%;
  max-height: 13em;
  overflow: hidden;
  position: relative;
}

.full-job-information {
  margin-top: 2%;
  overflow: auto;
}

.dont-show-continue {
  display: none;
  height: auto;
}

.skeleton-load {
  margin: 5%;
}

@media only screen and (max-width: 430px), screen and (max-height: 680px) {
  .continue-button {
    font-size: large;
  }
}

@media only screen and (max-width: 300px), screen and (max-height: 660px) {
  .continue-button {
    font-size: medium;
  }
}
