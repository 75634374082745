.data-description-container {
  white-space: pre-wrap;
  text-align: right;
}

.data-description-body {
  color: #0f40b4;
  margin-top: 5%;
  font-size: large;
  text-align: center;
}

.data-text {
  font-family: "Heebo-Regular", sans-serif;
}
