.headers-container {
  padding-top: 6%;
}

.logo-header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.share-header {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
}

.share-header-image {
  height: 50%;
  width: 70%;
}

@media only screen and (max-width: 380px) {
  .share-header-image {
    height: 50%;
    width: 100%;
  }

  .logo-header-image {
    width: 100%;
  }
}

@media only screen and (max-width: 330px) {
  .share-header-image {
    height: 50%;
    width: 200%;
  }
}
