.footer-container {
  text-align: center;
  padding-bottom: 1%;
  padding-top: 5%;
}

.footer-row {
  align-content: 0;
  align-items: 0;
  justify-content: 0;
  margin-bottom: 8%;
}

.interested-col {
  padding-right: 0;
  padding-left: 5px;
}

.uninterested-col {
  padding-right: 5px;
  padding-left: 0px;
}

.notInterested {
  width: 100%;
  cursor: pointer;
  margin-top: -5%;
  color: #0f40b4;
  text-decoration: underline;
  margin-bottom: 3%;
}
